import React, { useEffect } from "react";

import { useRouter } from "next/router";

interface RedirectProps {
  path: string;
}

export const Redirect: React.FC<React.PropsWithChildren<Readonly<RedirectProps>>> = ({ path }) => {
  const router = useRouter();
  useEffect(() => {
    void router.replace(path);
  });
  return <></>;
};
