import React from "react";

import { useRouter } from "next/router";

import type { PartnerTheme } from "./partner-theme-context";

export const ODXFlexportLogo: React.FC<React.PropsWithChildren<unknown>> = () => (
  <img src="/odx-flexport-logo.jpg" className="h-full py-0.5" />
);

const FlexportThemeBackground: React.FC<React.PropsWithChildren<unknown>> = () => (
  <div
    className="absolute top-0 bottom-0 left-0 right-0"
    style={{
      zIndex: -1,
      background:
        "url(/odx-flexport-bg.svg) no-repeat calc(50% - 200px) calc(50%), linear-gradient(180deg, #3E1D7F 0%, #131B2E 100%)",
    }}
  />
);

const CatalystThemeBackground: React.FC<React.PropsWithChildren<unknown>> = () => (
  <div
    className="absolute top-0 bottom-0 left-0 right-0"
    style={{
      zIndex: -1,
      background:
        "linear-gradient(rgb(62, 29, 127, .4) 0%, rgba(19, 27, 46, .12) 100%) 0% 0% / cover, url(/odx-catalyst-bg.jpg) center center no-repeat, linear-gradient(rgb(62, 29, 127) 0%, rgb(19, 27, 46) 100%)",
      backgroundSize: "cover",
    }}
  />
);

const partnerThemes: Record<string, PartnerTheme> = {
  flexport: {
    name: "Flexport",
    backgroundImage: FlexportThemeBackground,
    logo: ODXFlexportLogo,
    program: "odx",
  },
  catalyst: {
    name: "Catalyst",
    backgroundImage: CatalystThemeBackground,
    program: "odx",
  },
};

export const usePartnerThemeFromUrlParams = (): PartnerTheme => {
  const router = useRouter();
  const utmProgram = router.query.utm_program;
  const program = router.query.programSlug;

  if (typeof utmProgram === "string" && utmProgram in partnerThemes) {
    const theme = partnerThemes[utmProgram] ?? {};

    if (typeof theme.program !== "undefined" && theme.program !== program) {
      return {};
    }

    return theme;
  }

  return {};
};
