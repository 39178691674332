import React, { useEffect } from "react";

export function useClickOutside<T extends HTMLElement>(
  ref: React.MutableRefObject<T | null>,
  callback: (event: MouseEvent) => void,
): void {
  const handleClick = (e: MouseEvent): void => {
    if (ref.current && !ref.current.contains(e.target as Node)) {
      callback(e);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick, true);

    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  });
}
