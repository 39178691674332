import "tailwindcss/tailwind.css";
import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/noto-sans/700.css";
import "@fontsource/raleway/800.css";
import "@fontsource/raleway/500.css";
import "@fontsource/raleway/400.css";
import "@fontsource/raleway/200.css";
import "aos/dist/aos.css";
import "@on-deck/ui/styles/variables.css";

import React, { useEffect } from "react";

import { ApolloProvider } from "@apollo/client";
import { UserProvider as Auth0UserProvider } from "@auth0/nextjs-auth0";
import AOS from "aos";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import { Toaster } from "react-hot-toast";

import { QueryParamsPersister } from "components/analytics/query-params-persister";
import { ApplyDefaultSeo } from "components/apply-default-seo";
import { Layout } from "components/layout";
import { Redirect } from "components/redirect";
import { useApollo } from "lib/apollo";
import { usePartnerThemeFromUrlParams } from "lib/partner-theme";
import { PartnerThemeProvider } from "lib/partner-theme-context";
import { ApplicationStoreProvider } from "utils/application-store-context";
import { SessionUserProvider } from "utils/session-user-context";

declare module "react" {
  interface StyleHTMLAttributes<T> extends React.HTMLAttributes<T> {
    jsx?: boolean;
    global?: boolean;
  }
}

export default function App({ Component, pageProps }: AppProps): React.ReactElement<AppProps> {
  const router = useRouter();
  const theme = usePartnerThemeFromUrlParams();
  const apolloClient = useApollo(pageProps);

  useEffect(() => {
    AOS.init({
      easing: "ease-out-cubic",
      once: true,
      offset: 50,
    });
  }, []);

  if (process.env.NEXT_PUBLIC_WEBFLOW_REDIRECTS === "on") {
    const path = router.asPath.includes("/programs/odf")
      ? "https://forms.beondeck.com/t/x2fuWdEqkSus?utm_source=apply_legacy"
      : "https://beondeck.com/apply";
    return <Redirect path={path} />;
  }

  return (
    <>
      <ApplyDefaultSeo />
      <SessionUserProvider>
        <Auth0UserProvider>
          <ApolloProvider client={apolloClient}>
            <ApplicationStoreProvider>
              <PartnerThemeProvider value={theme}>
                <Layout>
                  <Component {...pageProps} />
                  <QueryParamsPersister />
                </Layout>
              </PartnerThemeProvider>
            </ApplicationStoreProvider>
          </ApolloProvider>
        </Auth0UserProvider>
      </SessionUserProvider>
      <Toaster
        position="top-right"
        toastOptions={{
          duration: 5000,
        }}
      />
      <style jsx global>{`
        body {
          background-color: #fafafa;
        }
      `}</style>
    </>
  );
}
