import { User } from "types/user.type";
import { storage } from "utils/storage";

import { HTTP_409_CONFLICT_ERROR, HTTP_422_VALIDATION_ERROR } from "./api-request";
import { createUnverifiedUserAndForgiveConflict } from "./unverified-users";

export const createSessionUserAndStore = async (email: string) => {
  email = email.trim().toLowerCase();

  const response = await fetch("/api/session", {
    method: "post",
    body: JSON.stringify({
      email,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (response.ok) {
    const result = await response.json();

    storage.setItem("ACCESS_TOKEN", result.accessToken);
    storage.setItem("email", email);
    storage.setItem("uid", result.uid);

    return {
      email,
      isVerified: false,
      provider: "session" as const,
      uid: result.userId,
    };
  }

  if (response.status === 409) {
    throw { code: HTTP_409_CONFLICT_ERROR, error: response };
  }

  if (response.status === 422) {
    throw { code: HTTP_422_VALIDATION_ERROR, error: response };
  }

  // throw everything else
  throw response;
};

// NOTE(valter): Server does not accept old Cotter ACCESS_TOKENs, but client
// thinks that the user is logged in. Therefore removing Cotter sessions
const removeCotterStorage = () => {
  try {
    const accessToken = storage.getItem("ACCESS_TOKEN");
    if (accessToken) {
      const parsed = JSON.parse(atob(accessToken.split(".")[1]));
      if (parsed.iss === "https://www.cotter.app") {
        clearSessionUserFromStorage();
      }
    }
  } catch {
    // NOTE(valter): expect to throw for non Cotter ACCESS_TOKENs
  }
};

export const getSessionUserFromStorage = (): User | undefined => {
  removeCotterStorage();

  const uid = storage.getItem("uid");
  const email = storage.getItem("email");

  if (email) {
    return {
      // NOTE(Hichem): we trim and lowercase in case storage has (lagacy) unclean email
      email: email.trim().toLowerCase(),
      uid: uid ?? undefined,
      isVerified: false,
      provider: "session",
    };
  }
};

export const clearSessionUserFromStorage = () => {
  storage.removeItem("ACCESS_TOKEN");
  storage.removeItem("email");
  storage.removeItem("uid");
};

/**
 * This unblocks the ux for an expired jwt session by ensuring an auth0 user exists for the provided email address (before an eventual redirect to email verification).
 *
 * NOTE(Hichem): This utility is currently more of a nice-to-have, than a necessity.
 * Auth0's passwordless login, by itself, would effectively create new auth0 users for never-seen-before emails (which would indeed unblock the expired jwt session ux), but it won't know to assign them to the Apply External User auth0 role.
 * This utility explicitely prepares an unverified auth0 user with the correct role, so we can keep consistency with the ordinary new user flow from createSessionUserAndStore().
 *
 * NOTE(Hichem): This utility effectively becomes a necessity when PR https://github.com/on-deck/on-deck/pull/4668 is merged.
 */
export const unblockExpiredSession = async (email: string) => {
  return createUnverifiedUserAndForgiveConflict(email.trim().toLowerCase());
};
