interface CampaignData {
  name?: string;
  source?: string;
  medium?: string;
  content?: string;
  term?: string;
}

const getCampaignData = (): CampaignData => {
  const campaignData: CampaignData = {};

  // See https://en.wikipedia.org/wiki/UTM_parameters#UTM_parameters
  const validUtmParams = ["utm_campaign", "utm_medium", "utm_source", "utm_content", "utm_term"];

  const cookieNameToPropertyName = {
    "on.deck.utm_campaign": "name",
    "on.deck.utm_source": "source",
    "on.deck.utm_medium": "medium",
    "on.deck.utm_content": "content",
    "on.deck.utm_term": "term",
  } as const;

  document.cookie
    .split(";")
    .filter((cookie) => cookie.trim().startsWith("on.deck."))
    .forEach((cookie) => {
      const [key, value] = cookie.trim().split("=");
      if (validUtmParams.includes(key.replace("on.deck.", ""))) {
        campaignData[cookieNameToPropertyName[key as keyof typeof cookieNameToPropertyName]] = value;
      }
    });

  return campaignData;
};

export { getCampaignData };
