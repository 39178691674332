import React, { useRef, useState } from "react";

import cx from "classnames";
import Link from "next/link";

import { useClickOutsideOrEscape } from "hooks/use-click-outside-or-escape";
import { useUser } from "hooks/use-user";

export interface UserDropdownProps {
  className?: string;
}

export const UserDropdown: React.FC<React.PropsWithChildren<Readonly<UserDropdownProps>>> = ({ className }) => {
  const { user } = useUser();

  const [showDropdown, setShowDropdown] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);

  useClickOutsideOrEscape(ref, () => {
    setShowDropdown(false);
  });

  return (
    <div className={cx("relative", className)}>
      <button
        ref={ref}
        className="flex items-center max-w-xs text-sm rounded-full focus:outline-none focus:bg-cool-gray-100 lg:p-2 lg:rounded-md lg:hover:bg-cool-gray-100"
        id="user-menu"
        aria-label="User menu"
        aria-haspopup="true"
        onClick={() => setShowDropdown((previous) => !previous)}
      >
        <p className="overflow-hidden text-sm font-medium leading-5 overflow-ellipsis max-w-24 xl:max-w-40 text-cool-gray-700">
          <span className="inline-flex items-center justify-center w-6 h-6 rounded-full bg-od-blue">
            <span className="text-xs font-medium leading-none text-white">{user?.email?.toUpperCase()[0]}</span>
          </span>
        </p>
        <svg
          className="flex-shrink-0 w-5 h-5 ml-1 text-cool-gray-400"
          xmlns="https://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
        </svg>
      </button>

      {showDropdown && (
        <div
          className="absolute right-0 z-20 w-64 py-2 mt-2 bg-white shadow-lg origin-top-right rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="user-menu"
        >
          <label className="block px-4 py-2 text-gray-500 lowercase truncate">
            {user?.email ? user.email : "Unverified"}
          </label>

          <div className="my-2 border-b" />

          <Link href="/applications" className="block px-4 py-2 text-gray-700 hover:bg-gray-100" role="menuitem">
            Your applications
          </Link>

          <a href="/api/auth/logout" className="block px-4 py-2 text-gray-700 hover:bg-gray-100" role="menuitem">
            Log out
          </a>
        </div>
      )}
    </div>
  );
};
