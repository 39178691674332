import React from "react";

import { CgArrowLeft } from "react-icons/cg";

import { Header } from "components/header";
import { useRudderAnalytics } from "hooks/use-rudder-analytics";
import { useSyncCookiesToLocalStorage } from "hooks/use-sync-cookies-to-local-storage";

export const Layout: React.FC<React.PropsWithChildren<Readonly<unknown>>> = ({ children }) => {
  useRudderAnalytics();
  useSyncCookiesToLocalStorage();

  return (
    <>
      <Header />

      {/* NOTE(steven): we need -mb-16 pb-16 since the Header is h-16; else, shows unnecessary whitespace at bottom */}
      <main className="relative min-h-screen pb-16 -mb-16 text-od-black">{children}</main>

      <footer className="w-full px-4 py-24 mx-auto text-white bg-od-deep-blue sm:px-6 lg:px-8">
        <nav className="justify-between max-w-3xl m-auto md:flex">
          <ul className="w-full lg:w-1/2 xl:w-1/3">
            <li className="mb-6">
              <a href="https://beondeck.com">
                <CgArrowLeft className="inline w-6 h-6 mr-2" />
                Main website
              </a>
            </li>
          </ul>

          <ul className="w-full lg:w-1/2 xl:w-1/3">
            <li className="mb-6">
              <a href="mailto:team@beondeck.com">team@beondeck.com</a>
            </li>
            <li className="mb-6">
              <a href="https://www.beondeck.com/privacy-policy">Privacy policy</a>
            </li>
            <li className="mb-6">
              <a href="https://www.beondeck.com/guiding-values">Guiding values</a>
            </li>
          </ul>
        </nav>
        <div className="max-w-3xl m-auto mt-12">
          <p>© Copyright 2021 nZero Labs, Inc.</p>
        </div>
      </footer>
    </>
  );
};
