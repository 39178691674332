import React, { createContext, useContext, useEffect, useState } from "react";

import { captureException } from "@sentry/nextjs";
import { compareDesc, parseISO } from "date-fns";
import Router from "next/router";

import { ApplyCohortYup, ApplyProgramYup, ApplyStepYup } from "features/program-applications/utils";
import { useUser } from "hooks/use-user";
import { Application, ApplicationData, ApplicationStatusEnum } from "types/application.type";

import { apiRequest, HTTP_403_FORBIDDEN_ERROR, HTTP_409_CONFLICT_ERROR } from "./api-request";
import { unblockExpiredSession } from "./session";

interface ContextProps {
  applications: Application[];
  loading: boolean;
  setLoading: (newValue: boolean) => void;
  getLatestApplications: () => Application[];
  findApplication: (programSlug: string, cohortSlug: string) => Application | undefined;
  createApplication: (
    programSlug: string,
    cohortSlug: string,
    newApplicationData: Partial<ApplicationData>,
  ) => Promise<Application>;
  saveApplication: (
    program: ApplyProgramYup,
    cohort: ApplyCohortYup,
    transformedApplicationStepData: Record<string, unknown>,
    currentApplicationStep: ApplyStepYup,
    status: string,
    submitting: boolean,
  ) => Promise<Application | void>;
}

export const CONFLICTING_APPLICATION_ALREADY_EXISTS_ERROR = "CONFLICTING_APPLICATION_ALREADY_EXISTS_ERROR";
export const EXPIRED_USER_SESSION_ERROR = "EXPIRED_USER_SESSION_ERROR";

const ApplicationStoreContext = createContext<ContextProps | null>(null);

/**
 * @example
 * compareNumbersDesc(1, 100) //  => 1
 * @param a First number to compare
 * @param b Second number to compare
 * @returns `1` if the first number is smaller than the second, `-1` if the first number is bigger than the second, or `0` if numbers are equal.
 * @example
 * // Sort an array of numbers:
 * const result = [10, 20, 1].sort(compareNumbersDesc)
 * //=> [20, 10, 1]
 */
const compareNumbersDesc = (a: number, b: number) => {
  if (a > b) {
    return -1;
  } else if (a === b) {
    return 0;
  } else {
    return 1;
  }
};

const compareApplicationDataLengthDesc = (a1: Application, a2: Application) => {
  const applicationDataSize1 = Object.keys(a1.data).length;
  const applicationDataSize2 = Object.keys(a2.data).length;

  return compareNumbersDesc(applicationDataSize1, applicationDataSize2);
};

const compareApplicationStatusDesc = (a1: Application, a2: Application) => {
  const isSubmitted1 = a1.status === ApplicationStatusEnum.SUBMITTED ? 1 : 0;
  const isSubmitted2 = a2.status === ApplicationStatusEnum.SUBMITTED ? 1 : 0;

  return compareNumbersDesc(isSubmitted1, isSubmitted2);
};

/**
 * NOTE(steven): we are using the following predicate:
 * - submitted apps preferred above all
 * - then, if both unsubmitted, "most completed"[1] apps take precedence.
 * - then, if both unsubmitted & equally completed, latest updated apps take precedence.
 * - finally, in the (unlikely) event that the applications complete equally, we take the first
 *
 * [1] where "most completed" is defined as the maximum number of keys the application data object holds
 */
const compareApplicationsDesc = (a1: Application, a2: Application) => {
  // if return if only one of two is submitted
  const statusComparison = compareApplicationStatusDesc(a1, a2);
  if (statusComparison !== 0) {
    return statusComparison;
  }
  // return if one application has more keys
  const applicationDataLengthComparison = compareApplicationDataLengthDesc(a1, a2);
  if (applicationDataLengthComparison !== 0) {
    return applicationDataLengthComparison;
  }
  // return if one application is submitted later
  const updatedAtComparison = compareDesc(parseISO(a1.updatedAt), parseISO(a2.updatedAt));
  if (updatedAtComparison != 0) {
    return updatedAtComparison;
  }
  // otherwise we return the first application
  return -1;
};

export const ApplicationStoreProvider: React.FC<React.PropsWithChildren<Readonly<unknown>>> = ({ children }) => {
  const { user } = useUser();

  const [applications, setApplications] = useState<Application[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!user) {
      setApplications([]);
      return;
    }

    const loadApplication = async (): Promise<void> => {
      try {
        setLoading(true);
        const response = await apiRequest<Application[]>({
          url: "/api/applications",
          method: "GET",
        });
        if (response) {
          setApplications(response);
        }
        setLoading(false);
      } catch (e) {
        if (e?.code === HTTP_409_CONFLICT_ERROR) {
          // NOTE(Hichem):
          // If a user arrives in this code block, this means they have one or more dedup winner applications associated with their email address,
          // but that are created with a different session than the one they are connecting with now (so, we need to redirect them for verification).

          // Admittedly, this here is a very rough way to redirect users for verification
          // But I'm having to do it like this, because I'm finding this more time efficient than handling the redirect at the many places where useApplicationStore() is called.
          // N.B. the number of existing users who may have to go through this code block is only around 16 or less (ref. https://linear.app/on-deck/issue/A2O-172#comment-33a5c01c).
          alert("To continue, please, verify your email address.");
          const redirectQuery = new URLSearchParams({
            email: user?.email ?? "",
            returnTo: Router.asPath,
          });
          await Router.push(`/api/auth/login?${redirectQuery.toString()}`);
        } else if (e?.code === HTTP_403_FORBIDDEN_ERROR) {
          alert("It has been a while since you last logged in. Please, verify your email address to continue.");
          await unblockExpiredSession(user?.email ?? "");
          const redirectQuery = new URLSearchParams({
            email: user?.email ?? "",
            returnTo: Router.asPath,
          });
          await Router.push(`/api/auth/login?${redirectQuery.toString()}`);
        } else {
          setLoading(false);
          throw e;
        }
      }
    };

    void loadApplication();
  }, [user]);

  const createApplication = async (
    programSlug: ApplyProgramYup["slug"],
    cohortSlug: ApplyCohortYup["slug"],
    newApplicationData: Partial<ApplicationData>,
  ): Promise<Application> => {
    const requestData = {
      data: newApplicationData,
      programSlug,
      cohortSlug,
      applicationStage: "email",
      applicationStatus: "Application Started",
    };
    try {
      const response = await apiRequest<Application>({
        url: "/api/applications",
        method: "POST",
        data: requestData,
      });
      addApplication(response);
      return response;
    } catch (error) {
      if (error?.code === HTTP_409_CONFLICT_ERROR) {
        throw new Error(CONFLICTING_APPLICATION_ALREADY_EXISTS_ERROR);
      } else if (error?.code === HTTP_403_FORBIDDEN_ERROR) {
        throw new Error(EXPIRED_USER_SESSION_ERROR);
      }
      captureException(error);
      throw error;
    }
  };

  const saveApplication = async (
    program: ApplyProgramYup,
    cohort: ApplyCohortYup,
    transformedApplicationStepData: Record<string, unknown>,
    currentApplicationStep: ApplyStepYup,
    status: string,
    submitting: boolean,
  ): Promise<Application | void> => {
    const existingApplication = findApplication(program.slug, cohort.slug);
    const requestData = {
      data: mergeApplicationData(program, cohort, existingApplication?.data, transformedApplicationStepData),
      programSlug: program.slug,
      cohortSlug: cohort.slug,
      applicationStage: submitting ? currentApplicationStep.slug : undefined,
      status,
    };
    try {
      const response = await apiRequest<Application>({
        url: "/api/applications",
        method: "POST",
        data: requestData,
      });

      if (response) {
        if (existingApplication) {
          updateApplication(existingApplication.id, response.data);
        } else {
          addApplication(response);
        }
      }

      return response;
    } catch (error) {
      if (error?.code === HTTP_409_CONFLICT_ERROR) {
        throw new Error(CONFLICTING_APPLICATION_ALREADY_EXISTS_ERROR);
      } else if (error?.code === HTTP_403_FORBIDDEN_ERROR) {
        throw new Error(EXPIRED_USER_SESSION_ERROR);
      }
      captureException(error);
      throw error;
    }
  };

  const mergeApplicationData = (
    program: ApplyProgramYup,
    cohort: ApplyCohortYup,
    existingApplicationData: Partial<ApplicationData> | undefined,
    transformedApplicationStepData: Record<string, unknown>,
  ): ApplicationData => ({
    program_title: program.shortTitle,
    cohort_title: cohort.name,
    ...existingApplicationData,
    ...transformedApplicationStepData,
  });

  const findApplication = (programSlug: string, cohortSlug: string): Application | undefined => {
    return applications?.find((a) => a.programSlug === programSlug && a.cohortSlug === cohortSlug);
  };

  const getLatestApplications = (): Application[] => {
    // NOTE(steven): we sort to prefer loading submitted / very far completed / recently updated apps first.
    applications?.sort(compareApplicationsDesc);

    return applications;
  };

  const updateApplication = (applicationId: string, applicationData: ApplicationData): void => {
    const updatedApplications = applications.map((a) => {
      if (a.id === applicationId) {
        return {
          ...a,
          data: applicationData,
        };
      }

      return a;
    });

    setApplications(updatedApplications);
  };

  const addApplication = (application: Application): void => {
    const updatedApplications = [...applications, application];
    setApplications(updatedApplications);
  };

  return (
    <ApplicationStoreContext.Provider
      value={{
        applications,
        setLoading,
        loading,
        findApplication,
        getLatestApplications,
        createApplication,
        saveApplication,
      }}
    >
      {children}
    </ApplicationStoreContext.Provider>
  );
};

export function useApplicationStore(): ContextProps {
  const applicationStoreContext = useContext(ApplicationStoreContext);
  if (!applicationStoreContext) {
    throw new TypeError("`useApplicationStore` must be called from within an `ApplicationStoreProvider`");
  }

  return applicationStoreContext;
}
