import { HTTP_409_CONFLICT_ERROR, HTTP_422_VALIDATION_ERROR } from "./api-request";

export const createUnverifiedUser = async (email: string) => {
  email = email.trim().toLowerCase();

  const response = await fetch("/api/unverified-users", {
    method: "post",
    body: JSON.stringify({
      email,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (response.ok) {
    return true;
  }

  if (response.status === 409) {
    throw { code: HTTP_409_CONFLICT_ERROR, error: response };
  }

  if (response.status === 422) {
    throw { code: HTTP_422_VALIDATION_ERROR, error: response };
  }

  // throw everything else
  throw response;
};

export const createUnverifiedUserAndForgiveConflict = async (email: string) => {
  try {
    return await createUnverifiedUser(email);
  } catch (error) {
    if (error?.code === HTTP_409_CONFLICT_ERROR) {
      // forgive HTTP 409
      return true;
    }
    // re-throw everything else
    throw error;
  }
};
