import React, { createContext, useContext, useEffect, useState } from "react";

import { User } from "types/user.type";
import { HTTP_409_CONFLICT_ERROR, HTTP_422_VALIDATION_ERROR } from "utils/api-request";
import { clearSessionUserFromStorage, createSessionUserAndStore, getSessionUserFromStorage } from "utils/session";

interface ContextProps {
  sessionUser?: User;
  createSessionUser: (email: string) => Promise<void>;
  logoutSessionUser: () => void;
}

export const CONFLICTING_SESSION_USER_ALREADY_EXISTS_ERROR = "CONFLICTING_SESSION_USER_ALREADY_EXISTS_ERROR";
export const INVALID_EMAIL_ERROR = "INVALID_EMAIL_ERROR";

export const SessionUserContext = createContext<ContextProps | null>(null);

export const SessionUserProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [sessionUser, setSessionUser] = useState<User>();

  useEffect(() => {
    // NOTE(valter): Cannot do this directly in useState, because accessing
    // localStorage so will not work with SSR
    setSessionUser(getSessionUserFromStorage());
  }, []);

  const logoutSessionUser = () => {
    setSessionUser(undefined);
    clearSessionUserFromStorage();
  };

  const createSessionUser = async (email: string) => {
    try {
      const user = await createSessionUserAndStore(email);
      setSessionUser(user);
    } catch (error) {
      if (error?.code === HTTP_409_CONFLICT_ERROR) {
        throw new Error(CONFLICTING_SESSION_USER_ALREADY_EXISTS_ERROR);
      } else if (error?.code === HTTP_422_VALIDATION_ERROR) {
        // NOTE(Hichem): For now, only expected HTTP 422 is for invalid emails.
        // This is to be improved if api evolves into possibly validating other things.
        throw new Error(INVALID_EMAIL_ERROR);
      }
      throw error;
    }
  };

  return (
    <SessionUserContext.Provider
      value={{
        sessionUser,
        createSessionUser,
        logoutSessionUser,
      }}
    >
      {children}
    </SessionUserContext.Provider>
  );
};

export function useSessionUser(): ContextProps {
  const sessionUserContext = useContext(SessionUserContext);
  if (!sessionUserContext) {
    throw new TypeError("`useUser` must be called from within an `SessionUserProvider`");
  }

  return sessionUserContext;
}
