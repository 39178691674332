/**
 * This client was automatically generated by RudderTyper. ** Do Not Edit **
 */
/**
 * Ajv is a peer dependency for development builds. It's used to apply run-time validation
 * to message payloads before passing them on to the underlying analytics instance.
 *
 * Note that the production bundle does not depend on Ajv.
 *
 * You can install it with: `npm install --save-dev ajv`.
 *
 * In order to support run-time validation of draft-04 JSON Schema we are using `ajv-draft-04`.
 * You can install it with: `npm install --save-dev ajv-draft-04`.
 *
 */
import Ajv from "ajv";
import AjvDraft4 from "ajv-draft-04";
/**
 * The default handler that is fired if none is supplied with setRudderTyperOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler = (message, violations) => {
  const msg = JSON.stringify(
    {
      type: "RudderTyper JSON Schema Validation Error",
      description:
        `You made an analytics call (${message.event}) using RudderTyper that doesn't match the ` +
        "Tracking Plan spec.",
      errors: violations,
    },
    undefined,
    2,
  );
  console.warn(msg);
};
let onViolation = defaultValidationErrorHandler;
let analytics = () => {
  return window.analytics;
};
/**
 * Updates the run-time configuration of this RudderTyper client.
 *
 * @param {RudderTyperOptions} options - the options to upsert
 *
 * @typedef {Object} RudderTyperOptions
 * @property {Rudder.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setRudderTyperOptions(options) {
  analytics = options.analytics ? () => options.analytics || window.analytics : analytics;
  onViolation = options.onViolation || onViolation;
}
/**
 * Validates a message against a JSON Schema using Ajv. If the message
 * is invalid, the `onViolation` handler will be called.
 */
function validateAgainstSchema(message, schema) {
  let ajv;
  if (schema["$schema"] && schema["$schema"].includes("draft-04")) {
    ajv = new AjvDraft4({
      allErrors: true,
      verbose: true,
    });
  } else {
    ajv = new Ajv({
      allErrors: true,
      verbose: true,
    });
    ajv.addMetaSchema(require("ajv/lib/refs/json-schema-draft-06.json"));
  }
  if (!ajv.validate(schema, message) && ajv.errors) {
    onViolation(message, ajv.errors);
  }
}
/**
 * Helper to attach metadata on RudderTyper to outbound requests.
 * This is used for attribution and debugging by the RudderStack team.
 */
function withRudderTyperContext(message = {}) {
  return {
    ...message,
    context: {
      ...(message.context || {}),
      ruddertyper: {
        sdk: "analytics.js",
        language: "javascript",
        rudderTyperVersion: "1.0.0-beta.5",
        trackingPlanId: "tp_25EgmiClbCFTlCK9Eebu0nROehh",
        trackingPlanVersion: "26",
      },
    },
  };
}
/**
 * @typedef PaymentPlanSelected
 * @property {string} application_id - Identifier for a specific application in Admit database
 * @property {string} checkout_type - Either 'admit' or 'airtable'.
 * @property {number} payment_plan_increment_amount - The amount charged per increment, if payed up front, the should be the full amount.
 * @property {number} payment_plan_increments - The number increments chosen, if payed up front this property = 1
 * @property {string} program_slug - Internal program identifier / program slug (founders, scale, sales etc.)
 */
/**
 * @typedef FormClosed
 * @property {number} application_step_index - CONTEXT property - describes which step in the Application flow is the event fired on - includes only the index, number of the step.
 * @property {string} application_step_slug - CONTEXT property - describes which step in the Application flow is the event fired on - includes only the slug, name of the step.
 * @property {string} program_cohort - CONTEXT property - describes which Program cohort the event is fired on.
 * @property {string | null} [program_slug] - Internal program identifier / program slug (founders, scale, sales etc.)
 */
/**
 * @typedef ButtonClicked
 * @property {string} button_text - Property sent with the button_clicked event, passing the text of the button.
 * @property {string | null} [placement] - Identifies placement on a page (nav-bar, header, cta, faq, footer etc.)

 * @property {string | null} [program_cohort] - CONTEXT property - describes which Program cohort the event is fired on.
 * @property {string | null} [program_slug] - Internal program identifier / program slug (founders, scale, sales etc.)
 */
/**
 * @typedef ApplyClicked
 * @property {boolean} is_waitlist - Identifies whether the button the user clicked on invited them to join a waitlist (i.e. included the word 'waitlist') or apply directly via the apply app.
 * @property {string} placement - Identifies placement on a page (nav-bar, header, cta, faq, footer etc.)

 * @property {string} program_slug - Internal program identifier / program slug (founders, scale, sales etc.)
 */
/**
 * @typedef ApplicationStarted
 * @property {string} program_cohort - CONTEXT property - describes which Program cohort the event is fired on.
 * @property {string | null} [program_slug] - Internal program identifier / program slug (founders, scale, sales etc.)
 */
/**
 * @typedef ApplicationSubmitted
 * @property {number} apply_application_id - Identifier for a specific application in Directory database
 * @property {string} program_cohort - CONTEXT property - describes which Program cohort the event is fired on.
 * @property {string | null} [program_slug] - Internal program identifier / program slug (founders, scale, sales etc.)
 */
/**
 * @typedef ApplicationContinued
 * @property {string} program_cohort - CONTEXT property - describes which Program cohort the event is fired on.
 * @property {string | null} [program_slug] - Internal program identifier / program slug (founders, scale, sales etc.)
 */
/**
 * @typedef ApplicationClosed
 * @property {string[]} [application_step] - CONTEXT property - describes which step in the Application flow is the event fired on - includes both the step number and the name in a list.

Example: [1, 'contact-details'] or [2, 'your-goals']
 * @property {string} program_cohort - CONTEXT property - describes which Program cohort the event is fired on.
 * @property {number} program_price - CONTEXT property - defines what the price of the Program that the user is applying for is.
 * @property {string} program_slug - CONTEXT property - describes what OnDeck Program the event is connected to. If the Apply page of ODS is visited, slug is 'ods'.

Examples: 'odx', 'odet', 'odf'
 */
/**
 * @typedef FaqViewed
 * @property {string} program_cohort - CONTEXT property - describes which Program cohort the event is fired on.
 * @property {string} program_slug - CONTEXT property - describes what OnDeck Program the event is connected to. If the Apply page of ODS is visited, slug is 'ods'.

Examples: 'odx', 'odet', 'odf'
 */
/**
 * @typedef VisionClicked
 * @property {string} placement - Identifies placement on a page (nav-bar, header, cta, faq, footer etc.)

 */
/**
 * @typedef PaymentFailed
 * @property {string} application_id - Identifier for a specific application
 * @property {string} payment_plan_increment_amount - The amount charged per increment, if payed up front, the should be the full amount.
 * @property {number} payment_plan_increments - The number increments chosen, if payed up front this property = 1
 * @property {string} program_slug - Internal program identifier / program slug (founders, scale, sales etc.)
 */
/**
 * @typedef ProgramPaneClicked
 * @property {string} program_pane - Property sent with the click on a different program pane on the homepage, describes which pane out of the available options is clicked.
 */
/**
 * @typedef PaymentPlansViewed
 * @property {string} application_id - Identifier for a specific application in Admit database
 * @property {string} checkout_type - Either 'admit' or 'airtable'.
 * @property {string} program_slug - Internal program identifier / program slug (founders, scale, sales etc.)
 */
/**
 * @typedef CheckoutAborted
 * @property {string} application_id - Identifier for a specific application in Admit database
 * @property {string} checkout_type - Either 'admit' or 'airtable'.
 * @property {string} program_slug - Internal program identifier / program slug (founders, scale, sales etc.)
 */
/**
 * @typedef ProgramClicked
 * @property {string} placement - Identifies placement on a page (nav-bar, header, cta, faq, footer etc.)

 * @property {string} program_slug - Internal program identifier / program slug (founders, scale, sales etc.)
 */
/**
 * @typedef DepthScrolled
 * @property {number | null} [cohort_price] - CONTEXT property - defines what the price of the Program that the user is applying for is.
 * @property {string | null} [program_cohort] - CONTEXT property - describes which Program cohort the event is fired on.
 * @property {string | null} [program_slug] - CONTEXT property - describes what OnDeck Program the event is connected to. If the Apply page of ODS is visited, slug is 'ods'.

Examples: 'odx', 'odet', 'odf'
 */
/**
 * @typedef FaqClicked
 * @property {string} placement - Identifies placement on a page (nav-bar, header, cta, faq, footer etc.)

 * @property {string} program_slug - Internal program identifier / program slug (founders, scale, sales etc.)
 * @property {string} question_text - The text of the FAQ question which was clicked.
 */
/**
 * @typedef ApplicationLeft
 * @property {number | null} [application_step_index] - CONTEXT property - describes which step in the Application flow is the event fired on - includes only the index, number of the step.
 * @property {string | null} [application_step_slug] - CONTEXT property - describes which step in the Application flow is the event fired on - includes only the slug, name of the step.
 * @property {string | null} [program_cohort] - CONTEXT property - describes which Program cohort the event is fired on.
 * @property {string | null} [program_slug] - Internal program identifier / program slug (founders, scale, sales etc.)
 */
/**
 * @typedef FormCompleted
 * @property {number | null} [application_step_index] - CONTEXT property - describes which step in the Application flow is the event fired on - includes only the index, number of the step.
 * @property {string} application_step_slug - CONTEXT property - describes which step in the Application flow is the event fired on - includes only the slug, name of the step.
 * @property {string} program_cohort - CONTEXT property - describes which Program cohort the event is fired on.
 * @property {string | null} [program_slug] - Internal program identifier / program slug (founders, scale, sales etc.)
 */
/**
 * @typedef FormNextClicked
 * @property {number} application_step_index - CONTEXT property - describes which step in the Application flow is the event fired on - includes only the index, number of the step.
 * @property {string} application_step_slug - CONTEXT property - describes which step in the Application flow is the event fired on - includes only the slug, name of the step.
 * @property {number | null} [cohort_price] - CONTEXT property - defines what the price of the Program that the user is applying for is.
 * @property {string} form_section_name - Name of the section of the form in which this event was triggered. Examples are 'Contact details' and 'Your application for OD50'. This is mapped from Tripetto's \"definition.name\" property.
 * @property {string} program_cohort - CONTEXT property - describes which Program cohort the event is fired on.
 * @property {string | null} [program_slug] - CONTEXT property - describes what OnDeck Program the event is connected to. If the Apply page of ODS is visited, slug is 'ods'.

Examples: 'odx', 'odet', 'odf'
 * @property {string} section_question_name - The name of the question which triggered this event. Examples are 'Your full name', 'What are you doing now?' and 'Why do you want to join On Deck?'. This is mapped from Tripetto's \"block.name\" property.
 */
/**
 * @typedef FormStarted
 * @property {number} application_step_index - CONTEXT property - describes which step in the Application flow is the event fired on - includes only the index, number of the step.
 * @property {string} application_step_slug - CONTEXT property - describes which step in the Application flow is the event fired on - includes only the slug, name of the step.
 * @property {string} program_cohort - CONTEXT property - describes which Program cohort the event is fired on.
 * @property {string | null} [program_slug] - Internal program identifier / program slug (founders, scale, sales etc.)
 */
/**
 * @typedef FormQuestionChanged
 * @property {number} application_step_index - CONTEXT property - describes which step in the Application flow is the event fired on - includes only the index, number of the step.
 * @property {string} application_step_slug - CONTEXT property - describes which step in the Application flow is the event fired on - includes only the slug, name of the step.
 * @property {string} form_section_name - Name of the section of the form in which this event was triggered. Examples are 'Contact details' and 'Your application for OD50'. This is mapped from Tripetto's \"definition.name\" property.
 * @property {string} program_cohort - CONTEXT property - describes which Program cohort the event is fired on.
 * @property {string | null} [program_slug] - Internal program identifier / program slug (founders, scale, sales etc.)
 * @property {string} section_question_name - The name of the question which triggered this event. Examples are 'Your full name', 'What are you doing now?' and 'Why do you want to join On Deck?'. This is mapped from Tripetto's \"block.name\" property.
 */
/**
 * @typedef OnboardingProgression
 * @property {string} step_name - Name of the specific onboarding step.
 * @property {number} step_number - Number of specific onboarding step.
 * @property {number} total_steps - Number of total steps of current onboarding funnel.
 */
/**
 * @typedef PaymentSuccessful
 * @property {string} application_id - Identifier for a specific application in Admit database
 * @property {string} checkout_type - Either 'admit' or 'airtable'.
 * @property {number} payment_plan_increment_amount - The amount charged per increment, if payed up front, the should be the full amount.
 * @property {number} payment_plan_increments - The number increments chosen, if payed up front this property = 1
 * @property {string} program_slug - Internal program identifier / program slug (founders, scale, sales etc.)
 */
/**
 * @typedef PaymentPlanDisplayed
 * @property {string} application_id - Identifier for a specific application in Admit database
 * @property {string} checkout_type - Either 'admit' or 'airtable'.
 * @property {number} payment_plan_increment_amount - The amount charged per increment, if payed up front, the should be the full amount.
 * @property {number} payment_plan_increments - The number increments chosen, if payed up front this property = 1
 * @property {string} program_slug - Internal program identifier / program slug (founders, scale, sales etc.)
 */
/**
 * When the users selects a payment plan.
 *
 * @param {PaymentPlanSelected} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paymentPlanSelected(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-06/schema#",
    description: "When the users selects a payment plan.",
    properties: {
      properties: {
        additionalProperties: false,
        properties: {
          application_id: {
            description: "Identifier for a specific application in Admit database",
            type: "string",
          },
          checkout_type: {
            description: "Either 'admit' or 'airtable'.",
            type: "string",
          },
          payment_plan_increment_amount: {
            description: "The amount charged per increment, if payed up front, the should be the full amount.",
            multipleOf: 1,
            type: "number",
          },
          payment_plan_increments: {
            description: "The number increments chosen, if payed up front this property = 1",
            multipleOf: 1,
            type: "number",
          },
          program_slug: {
            description: "Internal program identifier / program slug (founders, scale, sales etc.)",
            type: "string",
          },
        },
        required: [
          "application_id",
          "program_slug",
          "payment_plan_increment_amount",
          "payment_plan_increments",
          "checkout_type",
        ],
        type: "object",
      },
    },
    required: ["properties"],
    title: "payment_plan_selected",
    type: "object",
  };
  const message = {
    event: "payment_plan_selected",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track("payment_plan_selected", props || {}, withRudderTyperContext(options), callback);
  }
}
/**
 * Event fired when a Tripetto form is closed via the `X` button on the top-right.

Note: This event is different from `application_closed` which refers to the whole application tab being closed.
 *
 * @param {FormClosed} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function formClosed(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-06/schema#",
    description:
      "Event fired when a Tripetto form is closed via the `X` button on the top-right. \n\nNote: This event is different from `application_closed` which refers to the whole application tab being closed.",
    properties: {
      properties: {
        additionalProperties: false,
        properties: {
          application_step_index: {
            description:
              "CONTEXT property - describes which step in the Application flow is the event fired on - includes only the index, number of the step. ",
            multipleOf: 1,
            type: "number",
          },
          application_step_slug: {
            description:
              "CONTEXT property - describes which step in the Application flow is the event fired on - includes only the slug, name of the step.",
            type: "string",
          },
          program_cohort: {
            description: "CONTEXT property - describes which Program cohort the event is fired on. ",
            type: "string",
          },
          program_slug: {
            description: "Internal program identifier / program slug (founders, scale, sales etc.)",
            type: ["string", "null"],
          },
        },
        required: ["application_step_index", "program_cohort", "application_step_slug"],
        type: "object",
      },
    },
    required: ["properties"],
    title: "form_closed",
    type: "object",
  };
  const message = {
    event: "form_closed",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track("form_closed", props || {}, withRudderTyperContext(options), callback);
  }
}
/**
 * Event fired on all button clicks, as a catch-all.
 *
 * @param {ButtonClicked} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function buttonClicked(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-06/schema#",
    description: "Event fired on all button clicks, as a catch-all.",
    properties: {
      properties: {
        additionalProperties: false,
        properties: {
          button_text: {
            description: "Property sent with the button_clicked event, passing the text of the button. ",
            type: "string",
          },
          placement: {
            description: "Identifies placement on a page (nav-bar, header, cta, faq, footer etc.)\n",
            type: ["string", "null"],
          },
          program_cohort: {
            description: "CONTEXT property - describes which Program cohort the event is fired on. ",
            type: ["string", "null"],
          },
          program_slug: {
            description: "Internal program identifier / program slug (founders, scale, sales etc.)",
            type: ["string", "null"],
          },
        },
        required: ["button_text"],
        type: "object",
      },
    },
    required: ["properties"],
    title: "button_clicked",
    type: "object",
  };
  const message = {
    event: "button_clicked",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track("button_clicked", props || {}, withRudderTyperContext(options), callback);
  }
}
/**
 * Event fired when User clicks on a button to be redirected to an Application page of a Program.

The event can be fired via different buttons across the website, and each different button is marked with a different 'placement' property. (e.g. Program Info Body, NavBar). Event triggers: 1. . Trigger on www. View in Avo: https://www.avo.app/schemas/lOY16jw9d7I0kNjHLyNC/events/tK_BP56rY9/trigger/dzk8NFl29
 *
 * @param {ApplyClicked} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function applyClicked(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-06/schema#",
    description:
      "Event fired when User clicks on a button to be redirected to an Application page of a Program. \n\nThe event can be fired via different buttons across the website, and each different button is marked with a different 'placement' property. (e.g. Program Info Body, NavBar). Event triggers: 1. . Trigger on www. View in Avo: https://www.avo.app/schemas/lOY16jw9d7I0kNjHLyNC/events/tK_BP56rY9/trigger/dzk8NFl29",
    properties: {
      properties: {
        additionalProperties: false,
        properties: {
          is_waitlist: {
            description:
              "Identifies whether the button the user clicked on invited them to join a waitlist (i.e. included the word 'waitlist') or apply directly via the apply app.",
            type: "boolean",
          },
          placement: {
            description: "Identifies placement on a page (nav-bar, header, cta, faq, footer etc.)\n",
            type: "string",
          },
          program_slug: {
            description: "Internal program identifier / program slug (founders, scale, sales etc.)",
            type: "string",
          },
        },
        required: ["placement", "program_slug", "is_waitlist"],
        type: "object",
      },
    },
    required: ["properties"],
    title: "apply_clicked",
    type: "object",
  };
  const message = {
    event: "apply_clicked",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track("apply_clicked", props || {}, withRudderTyperContext(options), callback);
  }
}
/**
 * Event fired when a User submits their email that redirects to the first step of the Application. Event triggers: 1. . Trigger on Apply. View in Avo: https://www.avo.app/schemas/lOY16jw9d7I0kNjHLyNC/events/qEWBF--Kfh/trigger/p7Jv08_tf
 *
 * @param {ApplicationStarted} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function applicationStarted(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-06/schema#",
    description:
      "Event fired when a User submits their email that redirects to the first step of the Application. Event triggers: 1. . Trigger on Apply. View in Avo: https://www.avo.app/schemas/lOY16jw9d7I0kNjHLyNC/events/qEWBF--Kfh/trigger/p7Jv08_tf",
    properties: {
      properties: {
        additionalProperties: false,
        properties: {
          program_cohort: {
            description: "CONTEXT property - describes which Program cohort the event is fired on. ",
            type: "string",
          },
          program_slug: {
            description: "Internal program identifier / program slug (founders, scale, sales etc.)",
            type: ["string", "null"],
          },
        },
        required: ["program_cohort"],
        type: "object",
      },
    },
    required: ["properties"],
    title: "application_started",
    type: "object",
  };
  const message = {
    event: "application_started",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track("application_started", props || {}, withRudderTyperContext(options), callback);
  }
}
/**
 * Event is fired on click of the `Submit` button on the Review page of an Application.
 *
 * @param {ApplicationSubmitted} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function applicationSubmitted(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-06/schema#",
    description: "Event is fired on click of the `Submit` button on the Review page of an Application.",
    properties: {
      properties: {
        additionalProperties: false,
        properties: {
          apply_application_id: {
            description: "Identifier for a specific application in Directory database",
            multipleOf: 1,
            type: "number",
          },
          program_cohort: {
            description: "CONTEXT property - describes which Program cohort the event is fired on. ",
            type: "string",
          },
          program_slug: {
            description: "Internal program identifier / program slug (founders, scale, sales etc.)",
            type: ["string", "null"],
          },
        },
        required: ["program_cohort", "apply_application_id"],
        type: "object",
      },
    },
    required: ["properties"],
    title: "application_submitted",
    type: "object",
  };
  const message = {
    event: "application_submitted",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track("application_submitted", props || {}, withRudderTyperContext(options), callback);
  }
}
/**
 * Event fired when a User clicks the button to continue a previously started application. Event triggers: 1. . Trigger on Apply. View in Avo: https://www.avo.app/schemas/lOY16jw9d7I0kNjHLyNC/events/a_3uWu0Vx3/trigger/9vOjZzr_N
 *
 * @param {ApplicationContinued} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function applicationContinued(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-06/schema#",
    description:
      "Event fired when a User clicks the button to continue a previously started application. Event triggers: 1. . Trigger on Apply. View in Avo: https://www.avo.app/schemas/lOY16jw9d7I0kNjHLyNC/events/a_3uWu0Vx3/trigger/9vOjZzr_N",
    properties: {
      properties: {
        additionalProperties: false,
        properties: {
          program_cohort: {
            description: "CONTEXT property - describes which Program cohort the event is fired on. ",
            type: "string",
          },
          program_slug: {
            description: "Internal program identifier / program slug (founders, scale, sales etc.)",
            type: ["string", "null"],
          },
        },
        required: ["program_cohort"],
        type: "object",
      },
    },
    required: ["properties"],
    title: "application_continued",
    type: "object",
  };
  const message = {
    event: "application_continued",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track("application_continued", props || {}, withRudderTyperContext(options), callback);
  }
}
/**
 * Event fired when a user stops their Application in any way possible - either by closing the tab, going back to WWW, or going to a completely different website.
 *
 * @param {ApplicationClosed} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function applicationClosed(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-06/schema#",
    description:
      "Event fired when a user stops their Application in any way possible - either by closing the tab, going back to WWW, or going to a completely different website. ",
    properties: {
      properties: {
        additionalProperties: false,
        properties: {
          application_step: {
            description:
              "CONTEXT property - describes which step in the Application flow is the event fired on - includes both the step number and the name in a list. \n\nExample: [1, 'contact-details'] or [2, 'your-goals']",
            items: {
              type: ["string", "null"],
            },
            type: "array",
          },
          program_cohort: {
            description: "CONTEXT property - describes which Program cohort the event is fired on. ",
            type: "string",
          },
          program_price: {
            description: "CONTEXT property - defines what the price of the Program that the user is applying for is. ",
            type: "number",
          },
          program_slug: {
            description:
              "CONTEXT property - describes what OnDeck Program the event is connected to. If the Apply page of ODS is visited, slug is 'ods'. \n\nExamples: 'odx', 'odet', 'odf'",
            enum: [
              "odf",
              "odx",
              "ods",
              "oda",
              "odcs",
              "odflex",
              "odbd",
              "odds",
              "odm",
              "odpm",
              "odcos",
              "odd",
              "odnc",
              "odc-s",
              "odc-f",
              "odcb",
              "od50",
              "cod",
              "odct",
              "oddt",
              "odh",
              "odft",
              "odlb",
              "odet",
            ],
            type: "string",
          },
        },
        required: ["program_slug", "program_price", "program_cohort"],
        type: "object",
      },
    },
    required: ["properties"],
    title: "application_closed",
    type: "object",
  };
  const message = {
    event: "application_closed",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track("application_closed", props || {}, withRudderTyperContext(options), callback);
  }
}
/**
 * Event fired when the FAQ section is visible on User's screen.
 *
 * @param {FaqViewed} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function faqViewed(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-06/schema#",
    description: "Event fired when the FAQ section is visible on User's screen. ",
    properties: {
      properties: {
        additionalProperties: false,
        properties: {
          program_cohort: {
            description: "CONTEXT property - describes which Program cohort the event is fired on. ",
            type: "string",
          },
          program_slug: {
            description:
              "CONTEXT property - describes what OnDeck Program the event is connected to. If the Apply page of ODS is visited, slug is 'ods'. \n\nExamples: 'odx', 'odet', 'odf'",
            type: "string",
          },
        },
        required: ["program_slug", "program_cohort"],
        type: "object",
      },
    },
    required: ["properties"],
    title: "faq_viewed",
    type: "object",
  };
  const message = {
    event: "faq_viewed",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track("faq_viewed", props || {}, withRudderTyperContext(options), callback);
  }
}
/**
 * Event fired when User clicks on 'MORE ON ONDECK'S VISION' that leads to the Vision Tweet (https://twitter.com/beondeck/status/1345914609320960001)
 *
 * @param {VisionClicked} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function visionClicked(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-06/schema#",
    description:
      "Event fired when User clicks on 'MORE ON ONDECK'S VISION' that leads to the Vision Tweet (https://twitter.com/beondeck/status/1345914609320960001)",
    properties: {
      properties: {
        additionalProperties: false,
        properties: {
          placement: {
            description: "Identifies placement on a page (nav-bar, header, cta, faq, footer etc.)\n",
            type: "string",
          },
        },
        required: ["placement"],
        type: "object",
      },
    },
    required: ["properties"],
    title: "vision_clicked",
    type: "object",
  };
  const message = {
    event: "vision_clicked",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track("vision_clicked", props || {}, withRudderTyperContext(options), callback);
  }
}
/**
 * When a payment is attempted but fails and the user is redirected to the payment failed page.
 *
 * @param {PaymentFailed} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paymentFailed(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-06/schema#",
    description: "When a payment is attempted but fails and the user is redirected to the payment failed page.",
    properties: {
      properties: {
        additionalProperties: false,
        properties: {
          application_id: {
            description: "Identifier for a specific application",
            type: "string",
          },
          payment_plan_increment_amount: {
            description: "The amount charged per increment, if payed up front, the should be the full amount.",
            type: "string",
          },
          payment_plan_increments: {
            description: "The number increments chosen, if payed up front this property = 1",
            multipleOf: 1,
            type: "number",
          },
          program_slug: {
            description: "Internal program identifier / program slug (founders, scale, sales etc.)",
            type: "string",
          },
        },
        required: ["application_id", "program_slug", "payment_plan_increments", "payment_plan_increment_amount"],
        type: "object",
      },
    },
    required: ["properties"],
    title: "payment_failed",
    type: "object",
  };
  const message = {
    event: "payment_failed",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track("payment_failed", props || {}, withRudderTyperContext(options), callback);
  }
}
/**
 * Event fired when User clicks on a Program pane on the Homepage.
 *
 * @param {ProgramPaneClicked} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function programPaneClicked(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-06/schema#",
    description: "Event fired when User clicks on a Program pane on the Homepage. ",
    properties: {
      properties: {
        additionalProperties: false,
        properties: {
          program_pane: {
            description:
              "Property sent with the click on a different program pane on the homepage, describes which pane out of the available options is clicked. ",
            enum: ["startups", "investors", "sectors", "careers"],
            type: "string",
          },
        },
        required: ["program_pane"],
        type: "object",
      },
    },
    required: ["properties"],
    title: "program_pane_clicked",
    type: "object",
  };
  const message = {
    event: "program_pane_clicked",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track("program_pane_clicked", props || {}, withRudderTyperContext(options), callback);
  }
}
/**
 * When we display payment plans to the user we get their auth0 ID back as part of the payments data. We can use this to identify them.
 *
 * @param {PaymentPlansViewed} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paymentPlansViewed(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-06/schema#",
    description:
      "When we display payment plans to the user we get their auth0 ID back as part of the payments data. We can use this to identify them.",
    properties: {
      properties: {
        additionalProperties: false,
        properties: {
          application_id: {
            description: "Identifier for a specific application in Admit database",
            type: "string",
          },
          checkout_type: {
            description: "Either 'admit' or 'airtable'.",
            type: "string",
          },
          program_slug: {
            description: "Internal program identifier / program slug (founders, scale, sales etc.)",
            type: "string",
          },
        },
        required: ["application_id", "program_slug", "checkout_type"],
        type: "object",
      },
    },
    required: ["properties"],
    title: "payment_plans_viewed",
    type: "object",
  };
  const message = {
    event: "payment_plans_viewed",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track("payment_plans_viewed", props || {}, withRudderTyperContext(options), callback);
  }
}
/**
 * When the user closes the checkout flow (closes the tab or closes the browser window) without clicking on a payment plan.
 *
 * @param {CheckoutAborted} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutAborted(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-06/schema#",
    description:
      "When the user closes the checkout flow (closes the tab or closes the browser window) without clicking on a payment plan.",
    properties: {
      properties: {
        additionalProperties: false,
        properties: {
          application_id: {
            description: "Identifier for a specific application in Admit database",
            type: "string",
          },
          checkout_type: {
            description: "Either 'admit' or 'airtable'.",
            type: "string",
          },
          program_slug: {
            description: "Internal program identifier / program slug (founders, scale, sales etc.)",
            type: "string",
          },
        },
        required: ["application_id", "program_slug", "checkout_type"],
        type: "object",
      },
    },
    required: ["properties"],
    title: "checkout_aborted",
    type: "object",
  };
  const message = {
    event: "checkout_aborted",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track("checkout_aborted", props || {}, withRudderTyperContext(options), callback);
  }
}
/**
 * Event fired when User clicks a button to visit a Program Info page.

The event can be fired via different buttons across the website, and each different button is marked with a different 'origin' property. (e.g. Homepage 'Our Programs' Section, NavBar, Footer)

Depending on the Program, a different slug is attached as a property. (e.g. odf, odx, odds)
 *
 * @param {ProgramClicked} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function programClicked(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-06/schema#",
    description:
      "Event fired when User clicks a button to visit a Program Info page. \n\nThe event can be fired via different buttons across the website, and each different button is marked with a different 'origin' property. (e.g. Homepage 'Our Programs' Section, NavBar, Footer)\n\nDepending on the Program, a different slug is attached as a property. (e.g. odf, odx, odds)",
    properties: {
      properties: {
        additionalProperties: false,
        properties: {
          placement: {
            description: "Identifies placement on a page (nav-bar, header, cta, faq, footer etc.)\n",
            type: "string",
          },
          program_slug: {
            description: "Internal program identifier / program slug (founders, scale, sales etc.)",
            type: "string",
          },
        },
        required: ["placement", "program_slug"],
        type: "object",
      },
    },
    required: ["properties"],
    title: "program_clicked",
    type: "object",
  };
  const message = {
    event: "program_clicked",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track("program_clicked", props || {}, withRudderTyperContext(options), callback);
  }
}
/**
 * Event fired when a User enters a valid email and clicks Subscribe in the Footer 'Stay Updated' field.
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function emailSubscribed(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-06/schema#",
    description:
      "Event fired when a User enters a valid email and clicks Subscribe in the Footer 'Stay Updated' field.",
    properties: {},
    required: ["properties"],
    title: "email_subscribed",
    type: "object",
  };
  const message = {
    event: "email_subscribed",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track("email_subscribed", props || {}, withRudderTyperContext(options), callback);
  }
}
/**
 * [REVIEW] Event fired on User scrolling.

Track when a user scrolls up to the 25%, 50%, 75% and 100% depth of the page.
 *
 * @param {DepthScrolled} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function depthScrolled(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-06/schema#",
    description:
      "[REVIEW] Event fired on User scrolling. \n\nTrack when a user scrolls up to the 25%, 50%, 75% and 100% depth of the page. ",
    properties: {
      properties: {
        additionalProperties: false,
        properties: {
          cohort_price: {
            description: "CONTEXT property - defines what the price of the Program that the user is applying for is. ",
            type: ["number", "null"],
          },
          program_cohort: {
            description: "CONTEXT property - describes which Program cohort the event is fired on. ",
            type: ["string", "null"],
          },
          program_slug: {
            description:
              "CONTEXT property - describes what OnDeck Program the event is connected to. If the Apply page of ODS is visited, slug is 'ods'. \n\nExamples: 'odx', 'odet', 'odf'",
            type: ["string", "null"],
          },
        },
        required: [],
        type: "object",
      },
    },
    required: ["properties"],
    title: "depth_scrolled",
    type: "object",
  };
  const message = {
    event: "depth_scrolled",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track("depth_scrolled", props || {}, withRudderTyperContext(options), callback);
  }
}
/**
 * Event fired when a Frequently Asked Question on any Program is clicked.
 *
 * @param {FaqClicked} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function faqClicked(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-06/schema#",
    description: "Event fired when a Frequently Asked Question on any Program is clicked.",
    properties: {
      properties: {
        additionalProperties: false,
        properties: {
          placement: {
            description: "Identifies placement on a page (nav-bar, header, cta, faq, footer etc.)\n",
            type: "string",
          },
          program_slug: {
            description: "Internal program identifier / program slug (founders, scale, sales etc.)",
            type: "string",
          },
          question_text: {
            description: "The text of the FAQ question which was clicked.",
            type: "string",
          },
        },
        required: ["program_slug", "question_text", "placement"],
        type: "object",
      },
    },
    required: ["properties"],
    title: "faq_clicked",
    type: "object",
  };
  const message = {
    event: "faq_clicked",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track("faq_clicked", props || {}, withRudderTyperContext(options), callback);
  }
}
/**
 * Event fired when a user stops their Application in any way possible - either by closing the tab, going back to WWW, or going to a completely different website.
 *
 * @param {ApplicationLeft} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function applicationLeft(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-06/schema#",
    description:
      "Event fired when a user stops their Application in any way possible - either by closing the tab, going back to WWW, or going to a completely different website.",
    properties: {
      properties: {
        additionalProperties: false,
        properties: {
          application_step_index: {
            description:
              "CONTEXT property - describes which step in the Application flow is the event fired on - includes only the index, number of the step. ",
            multipleOf: 1,
            type: ["number", "null"],
          },
          application_step_slug: {
            description:
              "CONTEXT property - describes which step in the Application flow is the event fired on - includes only the slug, name of the step.",
            type: ["string", "null"],
          },
          program_cohort: {
            description: "CONTEXT property - describes which Program cohort the event is fired on. ",
            type: ["string", "null"],
          },
          program_slug: {
            description: "Internal program identifier / program slug (founders, scale, sales etc.)",
            type: ["string", "null"],
          },
        },
        required: [],
        type: "object",
      },
    },
    required: ["properties"],
    title: "application_left",
    type: "object",
  };
  const message = {
    event: "application_left",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track("application_left", props || {}, withRudderTyperContext(options), callback);
  }
}
/**
 * Event fired when a Tripetto form is submitted in an Application step.
 *
 * @param {FormCompleted} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function formCompleted(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-06/schema#",
    description: "Event fired when a Tripetto form is submitted in an Application step.",
    properties: {
      properties: {
        additionalProperties: false,
        properties: {
          application_step_index: {
            description:
              "CONTEXT property - describes which step in the Application flow is the event fired on - includes only the index, number of the step. ",
            multipleOf: 1,
            type: ["number", "null"],
          },
          application_step_slug: {
            description:
              "CONTEXT property - describes which step in the Application flow is the event fired on - includes only the slug, name of the step.",
            type: "string",
          },
          program_cohort: {
            description: "CONTEXT property - describes which Program cohort the event is fired on. ",
            type: "string",
          },
          program_slug: {
            description: "Internal program identifier / program slug (founders, scale, sales etc.)",
            type: ["string", "null"],
          },
        },
        required: ["program_cohort", "application_step_slug"],
        type: "object",
      },
    },
    required: ["properties"],
    title: "form_completed",
    type: "object",
  };
  const message = {
    event: "form_completed",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track("form_completed", props || {}, withRudderTyperContext(options), callback);
  }
}
/**
 * Event fired when a User moves between questions on a Tripetto form. They can do this by clicking 'Next', pressing tab (or shift-tab), or scrolling up and down the page.
 *
 * @param {FormNextClicked} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function formNextClicked(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-06/schema#",
    description:
      "Event fired when a User moves between questions on a Tripetto form. They can do this by clicking 'Next', pressing tab (or shift-tab), or scrolling up and down the page.",
    properties: {
      properties: {
        additionalProperties: false,
        properties: {
          application_step_index: {
            description:
              "CONTEXT property - describes which step in the Application flow is the event fired on - includes only the index, number of the step. ",
            multipleOf: 1,
            type: "number",
          },
          application_step_slug: {
            description:
              "CONTEXT property - describes which step in the Application flow is the event fired on - includes only the slug, name of the step.",
            type: "string",
          },
          cohort_price: {
            description: "CONTEXT property - defines what the price of the Program that the user is applying for is. ",
            type: ["number", "null"],
          },
          form_section_name: {
            description:
              "Name of the section of the form in which this event was triggered. Examples are 'Contact details' and 'Your application for OD50'. This is mapped from Tripetto's \\\"definition.name\\\" property.",
            type: "string",
          },
          program_cohort: {
            description: "CONTEXT property - describes which Program cohort the event is fired on. ",
            type: "string",
          },
          program_slug: {
            description:
              "CONTEXT property - describes what OnDeck Program the event is connected to. If the Apply page of ODS is visited, slug is 'ods'. \n\nExamples: 'odx', 'odet', 'odf'",
            type: ["string", "null"],
          },
          section_question_name: {
            description:
              "The name of the question which triggered this event. Examples are 'Your full name', 'What are you doing now?' and 'Why do you want to join On Deck?'. This is mapped from Tripetto's \\\"block.name\\\" property.",
            type: "string",
          },
        },
        required: [
          "application_step_index",
          "program_cohort",
          "application_step_slug",
          "section_question_name",
          "form_section_name",
        ],
        type: "object",
      },
    },
    required: ["properties"],
    title: "form_next_clicked",
    type: "object",
  };
  const message = {
    event: "form_next_clicked",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track("form_next_clicked", props || {}, withRudderTyperContext(options), callback);
  }
}
/**
 * Event fired when a Tripetto form is opened in an Application step.
 *
 * @param {FormStarted} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function formStarted(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-06/schema#",
    description: "Event fired when a Tripetto form is opened in an Application step.",
    properties: {
      properties: {
        additionalProperties: false,
        properties: {
          application_step_index: {
            description:
              "CONTEXT property - describes which step in the Application flow is the event fired on - includes only the index, number of the step. ",
            multipleOf: 1,
            type: "number",
          },
          application_step_slug: {
            description:
              "CONTEXT property - describes which step in the Application flow is the event fired on - includes only the slug, name of the step.",
            type: "string",
          },
          program_cohort: {
            description: "CONTEXT property - describes which Program cohort the event is fired on. ",
            type: "string",
          },
          program_slug: {
            description: "Internal program identifier / program slug (founders, scale, sales etc.)",
            type: ["string", "null"],
          },
        },
        required: ["application_step_index", "program_cohort", "application_step_slug"],
        type: "object",
      },
    },
    required: ["properties"],
    title: "form_started",
    type: "object",
  };
  const message = {
    event: "form_started",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track("form_started", props || {}, withRudderTyperContext(options), callback);
  }
}
/**
 * Event fired when a user changes the question they're focussed on in a Tripetto form. They can do this in many ways including pressing tab, clicking the next button and scrolling down the page. Users don't always go forward through a form. They often go forwards and backwards, in particular when they're scrolling to review their answers.
 *
 * @param {FormQuestionChanged} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function formQuestionChanged(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-06/schema#",
    description:
      "Event fired when a user changes the question they're focussed on in a Tripetto form. They can do this in many ways including pressing tab, clicking the next button and scrolling down the page. Users don't always go forward through a form. They often go forwards and backwards, in particular when they're scrolling to review their answers.",
    properties: {
      properties: {
        additionalProperties: false,
        properties: {
          application_step_index: {
            description:
              "CONTEXT property - describes which step in the Application flow is the event fired on - includes only the index, number of the step. ",
            multipleOf: 1,
            type: "number",
          },
          application_step_slug: {
            description:
              "CONTEXT property - describes which step in the Application flow is the event fired on - includes only the slug, name of the step.",
            type: "string",
          },
          form_section_name: {
            description:
              "Name of the section of the form in which this event was triggered. Examples are 'Contact details' and 'Your application for OD50'. This is mapped from Tripetto's \\\"definition.name\\\" property.",
            type: "string",
          },
          program_cohort: {
            description: "CONTEXT property - describes which Program cohort the event is fired on. ",
            type: "string",
          },
          program_slug: {
            description: "Internal program identifier / program slug (founders, scale, sales etc.)",
            type: ["string", "null"],
          },
          section_question_name: {
            description:
              "The name of the question which triggered this event. Examples are 'Your full name', 'What are you doing now?' and 'Why do you want to join On Deck?'. This is mapped from Tripetto's \\\"block.name\\\" property.",
            type: "string",
          },
        },
        required: [
          "application_step_index",
          "program_cohort",
          "application_step_slug",
          "section_question_name",
          "form_section_name",
        ],
        type: "object",
      },
    },
    required: ["properties"],
    title: "form_question_changed",
    type: "object",
  };
  const message = {
    event: "form_question_changed",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track("form_question_changed", props || {}, withRudderTyperContext(options), callback);
  }
}
/**
 * When the users starts the onbarding flow
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function onboardingStarted(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-06/schema#",
    description: "When the users starts the onbarding flow",
    properties: {},
    required: ["properties"],
    title: "onboarding_started",
    type: "object",
  };
  const message = {
    event: "onboarding_started",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track("onboarding_started", props || {}, withRudderTyperContext(options), callback);
  }
}
/**
 * When the users completes the onboarding flow.
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function onboardingCompleted(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-06/schema#",
    description: "When the users completes the onboarding flow.",
    properties: {},
    required: ["properties"],
    title: "onboarding_completed",
    type: "object",
  };
  const message = {
    event: "onboarding_completed",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track("onboarding_completed", props || {}, withRudderTyperContext(options), callback);
  }
}
/**
 * Fired once for each step of progression of the current onboarding flow
 *
 * @param {OnboardingProgression} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function onboardingProgression(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-06/schema#",
    description: "Fired once for each step of progression of the current onboarding flow",
    properties: {
      properties: {
        additionalProperties: false,
        properties: {
          step_name: {
            description: "Name of the specific onboarding step.",
            type: "string",
          },
          step_number: {
            description: "Number of specific onboarding step.",
            multipleOf: 1,
            type: "number",
          },
          total_steps: {
            description: "Number of total steps of current onboarding funnel.",
            multipleOf: 1,
            type: "number",
          },
        },
        required: ["step_number", "step_name", "total_steps"],
        type: "object",
      },
    },
    required: ["properties"],
    title: "onboarding_progression",
    type: "object",
  };
  const message = {
    event: "onboarding_progression",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track("onboarding_progression", props || {}, withRudderTyperContext(options), callback);
  }
}
/**
 * When a users successfully completes the checkout flow and is redirected to the payment success page.
 *
 * @param {PaymentSuccessful} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paymentSuccessful(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-06/schema#",
    description: "When a users successfully completes the checkout flow and is redirected to the payment success page.",
    properties: {
      properties: {
        additionalProperties: false,
        properties: {
          application_id: {
            description: "Identifier for a specific application in Admit database",
            type: "string",
          },
          checkout_type: {
            description: "Either 'admit' or 'airtable'.",
            type: "string",
          },
          payment_plan_increment_amount: {
            description: "The amount charged per increment, if payed up front, the should be the full amount.",
            multipleOf: 1,
            type: "number",
          },
          payment_plan_increments: {
            description: "The number increments chosen, if payed up front this property = 1",
            multipleOf: 1,
            type: "number",
          },
          program_slug: {
            description: "Internal program identifier / program slug (founders, scale, sales etc.)",
            type: "string",
          },
        },
        required: [
          "application_id",
          "program_slug",
          "payment_plan_increment_amount",
          "payment_plan_increments",
          "checkout_type",
        ],
        type: "object",
      },
    },
    required: ["properties"],
    title: "payment_successful",
    type: "object",
  };
  const message = {
    event: "payment_successful",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track("payment_successful", props || {}, withRudderTyperContext(options), callback);
  }
}
/**
 * When a payment plan option is displayed. Multiple events can fire at the same time. This event is fired without user interaction. It simply signifies that the user has been shown each option for which the event is fired.
 *
 * @param {PaymentPlanDisplayed} props - The analytics properties that will be sent to RudderStack.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paymentPlanDisplayed(props, options, callback) {
  const schema = {
    $schema: "http://json-schema.org/draft-06/schema#",
    description:
      "When a payment plan option is displayed. Multiple events can fire at the same time. This event is fired without user interaction. It simply signifies that the user has been shown each option for which the event is fired.",
    properties: {
      properties: {
        additionalProperties: false,
        properties: {
          application_id: {
            description: "Identifier for a specific application in Admit database",
            type: "string",
          },
          checkout_type: {
            description: "Either 'admit' or 'airtable'.",
            type: "string",
          },
          payment_plan_increment_amount: {
            description: "The amount charged per increment, if payed up front, the should be the full amount.",
            multipleOf: 1,
            type: "number",
          },
          payment_plan_increments: {
            description: "The number increments chosen, if payed up front this property = 1",
            multipleOf: 1,
            type: "number",
          },
          program_slug: {
            description: "Internal program identifier / program slug (founders, scale, sales etc.)",
            type: "string",
          },
        },
        required: [
          "application_id",
          "program_slug",
          "payment_plan_increments",
          "payment_plan_increment_amount",
          "checkout_type",
        ],
        type: "object",
      },
    },
    required: ["properties"],
    title: "payment_plan_displayed",
    type: "object",
  };
  const message = {
    event: "payment_plan_displayed",
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);
  const a = analytics();
  if (a) {
    a.track("payment_plan_displayed", props || {}, withRudderTyperContext(options), callback);
  }
}
const clientAPI = {
  /**
   * Updates the run-time configuration of this RudderTyper client.
   *
   * @param {RudderTyperOptions} options - the options to upsert
   *
   * @typedef {Object} RudderTyperOptions
   * @property {Rudder.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
   * 		calls are forwarded on to. Defaults to window.analytics.
   * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
   * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
   * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
   * 		will be logged to stderr.
   */
  setRudderTyperOptions,
  /**
   * When the users selects a payment plan.
   *
   * @param {PaymentPlanSelected} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  paymentPlanSelected,
  /**
     * Event fired when a Tripetto form is closed via the `X` button on the top-right.

    Note: This event is different from `application_closed` which refers to the whole application tab being closed.
     *
     * @param {FormClosed} props - The analytics properties that will be sent to RudderStack.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
  formClosed,
  /**
   * Event fired on all button clicks, as a catch-all.
   *
   * @param {ButtonClicked} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  buttonClicked,
  /**
     * Event fired when User clicks on a button to be redirected to an Application page of a Program.

    The event can be fired via different buttons across the website, and each different button is marked with a different 'placement' property. (e.g. Program Info Body, NavBar). Event triggers: 1. . Trigger on www. View in Avo: https://www.avo.app/schemas/lOY16jw9d7I0kNjHLyNC/events/tK_BP56rY9/trigger/dzk8NFl29
     *
     * @param {ApplyClicked} props - The analytics properties that will be sent to RudderStack.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
  applyClicked,
  /**
   * Event fired when a User submits their email that redirects to the first step of the Application. Event triggers: 1. . Trigger on Apply. View in Avo: https://www.avo.app/schemas/lOY16jw9d7I0kNjHLyNC/events/qEWBF--Kfh/trigger/p7Jv08_tf
   *
   * @param {ApplicationStarted} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  applicationStarted,
  /**
   * Event is fired on click of the `Submit` button on the Review page of an Application.
   *
   * @param {ApplicationSubmitted} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  applicationSubmitted,
  /**
   * Event fired when a User clicks the button to continue a previously started application. Event triggers: 1. . Trigger on Apply. View in Avo: https://www.avo.app/schemas/lOY16jw9d7I0kNjHLyNC/events/a_3uWu0Vx3/trigger/9vOjZzr_N
   *
   * @param {ApplicationContinued} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  applicationContinued,
  /**
   * Event fired when a user stops their Application in any way possible - either by closing the tab, going back to WWW, or going to a completely different website.
   *
   * @param {ApplicationClosed} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  applicationClosed,
  /**
   * Event fired when the FAQ section is visible on User's screen.
   *
   * @param {FaqViewed} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  faqViewed,
  /**
   * Event fired when User clicks on 'MORE ON ONDECK'S VISION' that leads to the Vision Tweet (https://twitter.com/beondeck/status/1345914609320960001)
   *
   * @param {VisionClicked} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  visionClicked,
  /**
   * When a payment is attempted but fails and the user is redirected to the payment failed page.
   *
   * @param {PaymentFailed} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  paymentFailed,
  /**
   * Event fired when User clicks on a Program pane on the Homepage.
   *
   * @param {ProgramPaneClicked} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  programPaneClicked,
  /**
   * When we display payment plans to the user we get their auth0 ID back as part of the payments data. We can use this to identify them.
   *
   * @param {PaymentPlansViewed} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  paymentPlansViewed,
  /**
   * When the user closes the checkout flow (closes the tab or closes the browser window) without clicking on a payment plan.
   *
   * @param {CheckoutAborted} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  checkoutAborted,
  /**
     * Event fired when User clicks a button to visit a Program Info page.

    The event can be fired via different buttons across the website, and each different button is marked with a different 'origin' property. (e.g. Homepage 'Our Programs' Section, NavBar, Footer)

    Depending on the Program, a different slug is attached as a property. (e.g. odf, odx, odds)
     *
     * @param {ProgramClicked} props - The analytics properties that will be sent to RudderStack.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
  programClicked,
  /**
   * Event fired when a User enters a valid email and clicks Subscribe in the Footer 'Stay Updated' field.
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  emailSubscribed,
  /**
     * [REVIEW] Event fired on User scrolling.

    Track when a user scrolls up to the 25%, 50%, 75% and 100% depth of the page.
     *
     * @param {DepthScrolled} props - The analytics properties that will be sent to RudderStack.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
  depthScrolled,
  /**
   * Event fired when a Frequently Asked Question on any Program is clicked.
   *
   * @param {FaqClicked} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  faqClicked,
  /**
   * Event fired when a user stops their Application in any way possible - either by closing the tab, going back to WWW, or going to a completely different website.
   *
   * @param {ApplicationLeft} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  applicationLeft,
  /**
   * Event fired when a Tripetto form is submitted in an Application step.
   *
   * @param {FormCompleted} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  formCompleted,
  /**
   * Event fired when a User moves between questions on a Tripetto form. They can do this by clicking 'Next', pressing tab (or shift-tab), or scrolling up and down the page.
   *
   * @param {FormNextClicked} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  formNextClicked,
  /**
   * Event fired when a Tripetto form is opened in an Application step.
   *
   * @param {FormStarted} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  formStarted,
  /**
   * Event fired when a user changes the question they're focussed on in a Tripetto form. They can do this in many ways including pressing tab, clicking the next button and scrolling down the page. Users don't always go forward through a form. They often go forwards and backwards, in particular when they're scrolling to review their answers.
   *
   * @param {FormQuestionChanged} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  formQuestionChanged,
  /**
   * When the users starts the onbarding flow
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  onboardingStarted,
  /**
   * When the users completes the onboarding flow.
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  onboardingCompleted,
  /**
   * Fired once for each step of progression of the current onboarding flow
   *
   * @param {OnboardingProgression} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  onboardingProgression,
  /**
   * When a users successfully completes the checkout flow and is redirected to the payment success page.
   *
   * @param {PaymentSuccessful} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  paymentSuccessful,
  /**
   * When a payment plan option is displayed. Multiple events can fire at the same time. This event is fired without user interaction. It simply signifies that the user has been shown each option for which the event is fired.
   *
   * @param {PaymentPlanDisplayed} props - The analytics properties that will be sent to RudderStack.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  paymentPlanDisplayed,
};
export default new Proxy(clientAPI, {
  get(target, method) {
    if (typeof method === "string" && target.hasOwnProperty(method)) {
      return target[method];
    }
    return () => {
      console.warn(`⚠️  You made an analytics call (${String(method)}) that can't be found. Either:
    a) Re-generate your ruddertyper client: \`npx rudder-typer\`
    b) Add it to your Tracking Plan: https://api.rudderstack.com/trackingplans/tp_25EgmiClbCFTlCK9Eebu0nROehh`);
      const a = analytics();
      if (a) {
        a.track(
          "Unknown Analytics Call Fired",
          {
            method,
          },
          withRudderTyperContext(),
        );
      }
    };
  },
});
