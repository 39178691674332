import { storage } from "utils/storage";

interface RequestArguments {
  url: string;
  method: string;
  data?: unknown;
  headers?: Record<string, string>;
  isBlob?: boolean;
}

export const HTTP_403_FORBIDDEN_ERROR = "HTTP_403_FORBIDDEN_ERROR";
export const HTTP_409_CONFLICT_ERROR = "HTTP_409_CONFLICT_ERROR";
export const HTTP_422_VALIDATION_ERROR = "HTTP_422_VALIDATION_ERROR";

export const apiRequest = async <T>(args: RequestArguments): Promise<T> => {
  const response = await makeRequest(args);
  return await handleResponse<T>(response, args);
};

// PRIVATE
const handleResponse = async <T>(response: Response, args: RequestArguments): Promise<T> => {
  if (response.ok) {
    if (args.isBlob) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return await response.blob();
    }
    return await response.json();
  } else if (response.status === 403) {
    throw { code: HTTP_403_FORBIDDEN_ERROR, error: response };
  } else if (response.status === 409) {
    throw { code: HTTP_409_CONFLICT_ERROR, error: response };
  } else if (response.status === 422) {
    throw { code: HTTP_422_VALIDATION_ERROR, error: response };
  } else {
    throw new Error(`Request failed: ${args.url}, ${args.method}`);
  }
};

const makeRequest = async (args: RequestArguments): Promise<Response> => {
  const { url, method, data, headers = {} } = args;
  const token = storage.getItem("ACCESS_TOKEN");
  return await fetch(url, {
    method,
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      ...headers,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
