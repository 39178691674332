import React from "react";

import { DefaultSeo } from "next-seo";

const defaultDescription =
  "On Deck is where top talent comes to accelerate their ideas and careers, surrounded by a world-class community.";

export const ApplyDefaultSeo: React.FC<React.PropsWithChildren<unknown>> = () => (
  <DefaultSeo
    titleTemplate="%s | On Deck"
    defaultTitle="Apply to On Deck"
    description={defaultDescription}
    openGraph={{
      type: "website",
      locale: "en_US",
      url: "https://apply.beondeck.com",
      site_name: "Apply to On Deck",
      description: defaultDescription,
    }}
  />
);
