import { useEffect } from "react";

import { Router } from "next/router";

import rudderTyper from "@on-deck/shared/rudder-typer";
import { getCampaignData } from "utils/get-campaign-data";

import { useUser } from "./use-user";

const useRudderAnalytics = (): void => {
  // Identify events
  const { user } = useUser();

  useEffect(() => {
    if (user?.uid || user?.email) {
      window.rudderanalytics?.identify(user?.uid ?? "", { uid: user?.uid, email: user?.email });
    }
  }, [user]);

  // Page events
  useEffect(() => {
    function handleRouteChangeComplete() {
      window.rudderanalytics?.page(undefined, undefined, undefined, { campaign: getCampaignData() });
    }

    Router.events.on("routeChangeComplete", handleRouteChangeComplete);

    return () => Router.events.off("routeChangeComplete", handleRouteChangeComplete);
  }, []);

  // Track Events (managed using Avo)
  useEffect(() => {
    window.rudderanalytics?.ready(() =>
      rudderTyper.setRudderTyperOptions({
        analytics: window.rudderanalytics,
      }),
    );
  }, []);
};

export { useRudderAnalytics };
