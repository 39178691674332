import { useMemo } from "react";

import { useUser as useAuth0User } from "@auth0/nextjs-auth0";

import { User } from "types/user.type";
import { useSessionUser } from "utils/session-user-context";

export const useUser = () => {
  const { sessionUser, logoutSessionUser } = useSessionUser();
  const { user: auth0User, isLoading: auth0Loading } = useAuth0User();

  const user: User | undefined = useMemo(() => {
    if (auth0User) {
      // NOTE(valter): When we have a Auth0 user, we should clear session user
      // so that when we log out Auth0 user, it won't fall back to session user
      logoutSessionUser();

      return {
        uid: auth0User.sub!,
        email: auth0User.email?.toLowerCase() as string,
        isVerified: true,
        provider: "oauth",
      };
    }

    return sessionUser;
  }, [auth0User, sessionUser]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    user,
    loading: auth0Loading,
  };
};
