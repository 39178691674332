import { ApolloClient, from, HttpLink, InMemoryCache, NormalizedCacheObject } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import generatedIntrospection from "@graphql-types/introspection-result";
import { captureMessage } from "@sentry/nextjs";
export const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";
import { useMemo } from "react";

import merge from "deepmerge";
import isEqual from "lodash.isequal";

import { APPLY_GUEST_ALREADY_EXISTS_ERROR } from "@on-deck/universal/constants/apply";

type IApolloClient = ReturnType<typeof createApolloClient>;

/** Acceptable graphql errors we do not want (or need) to pass to Sentry */
const IGNORED_APP_ERRORS = [APPLY_GUEST_ALREADY_EXISTS_ERROR];

let apolloClient: IApolloClient;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors
      .filter(({ message }) => !IGNORED_APP_ERRORS.includes(message))
      .forEach(({ message, locations, path }) => {
        captureMessage(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      });
  if (networkError) captureMessage(`[Network error]: ${networkError}`);
});

// note (richard): we're hitting the API directly, because the authentication proxy set up in other apps (e.g. directory) doesn't work with SSG as next builds the graphql pages **before** the api/ pages are built.
// NOT sure if this is still the case after we have the handlerFactory -> need to test
const httpLink = new HttpLink({
  uri: `${process.env.ON_DECK_API_URL}/graphql`, // Server URL (must be absolute for ssr),
});

function createApolloClient() {
  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: from([errorLink, httpLink]),
    cache: new InMemoryCache({ possibleTypes: generatedIntrospection.possibleTypes }),
  });
}

export function initializeApollo(initialState: NormalizedCacheObject | null = null) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
    const data = merge(existingCache, initialState, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function addApolloState(client: IApolloClient, pageProps: any) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }
  return pageProps;
}

export function useApollo(pageProps: any) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  return useMemo(() => initializeApollo(state), [state]);
}
