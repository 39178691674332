import { useEffect } from "react";

import { useClickOutside } from "hooks/use-click-outside";

export function useClickOutsideOrEscape<T extends HTMLElement>(
  ref: React.MutableRefObject<T | null>,
  callback: (event: MouseEvent | KeyboardEvent) => void,
): void {
  useClickOutside(ref, callback);

  const handleKeyDown = (e: KeyboardEvent): void => {
    if (e.key === "Escape") {
      callback(e);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, true);

    return () => {
      document.removeEventListener("keydown", handleKeyDown, true);
    };
  });
}
