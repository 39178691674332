import React from "react";

import cx from "classnames";
import Link from "next/link";

import { Logo } from "components/logo";
import { UserDropdown } from "components/user-dropdown";
import { useUser } from "hooks/use-user";
import { usePartnerTheme } from "lib/partner-theme-context";

import styles from "./header.module.css";

export const Header: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { user } = useUser();
  const { logo: PartnerLogo } = usePartnerTheme();

  return (
    <header className={styles.root}>
      <div className="flex items-center h-16 px-2 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <Link href="/" className="h-6 cursor-pointer text-od-dark-blue hover:text-blue-500">
          {PartnerLogo ? <PartnerLogo /> : <Logo className="h-full" />}
        </Link>

        {user?.isVerified ? (
          <UserDropdown className={styles.dropdown} />
        ) : (
          <a
            href="/api/auth/login?returnTo=/applications"
            className={cx(styles.login, "text-sm font-medium text-gray-900 border-b-2 border-od-blue")}
          >
            Log in
          </a>
        )}
      </div>
    </header>
  );
};
