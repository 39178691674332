import { useEffect, VFC } from "react";

import qs from "qs";

import { storage } from "utils/storage";

export const QueryParamsPersister: VFC = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
      for (const [k, v] of Object.entries(params)) {
        if (v) {
          storage.setItem(k, v.toString());
        }
      }
    }
  }, []);
  return null;
};
