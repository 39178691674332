import { useEffect } from "react";

import { storage } from "utils/storage";

/**
 * When a potential applicant follows a referral link to www the query params
 * which indicate that they were referred and who by are stored in a cookie
 * in order that they can be retrieved by apply and used for analytics.
 *
 * This function grabs all 'on.deck.' cookies and drops them into local storage
 * which is where the Tripetto form looks for them in order to trigger the
 * 'How do you know x?' question in the application flow.
 *
 * Here we simply grab all the cookies which start with 'on.deck.'. On www we
 * have a filter which only creates cookies for search params we're interested
 * in. The code for this is in the web-analytics repo:
 *
 * https://github.com/on-deck/web-analytics
 */
const useSyncCookiesToLocalStorage = (): void => {
  useEffect(() => {
    const paramsToSync: string[] = ["email", "od_id", "referral", "utm_campaign", "utm_medium", "utm_source"];

    document.cookie
      .split(";")
      .filter((cookie) => cookie.trim().startsWith("on.deck."))
      .forEach((cookie) => {
        const [key, value] = cookie.trim().split("=");
        if (paramsToSync.includes(key.replace("on.deck.", ""))) {
          storage.setItem(key.replace("on.deck.", ""), decodeURIComponent(value));
        }
      });
  }, []);
};

export { useSyncCookiesToLocalStorage };
