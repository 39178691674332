import { createContext, useContext } from "react";

export type PartnerTheme = {
  name?: string;
  logo?: React.FC<React.PropsWithChildren<unknown>>;
  backgroundImage?: React.FC<React.PropsWithChildren<unknown>>;
  program?: string;
};

const PartnerThemeContext = createContext<PartnerTheme>({});

export const PartnerThemeProvider = PartnerThemeContext.Provider;

export const usePartnerTheme = () => useContext(PartnerThemeContext);
