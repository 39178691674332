export enum ApplicationStatusEnum {
  IN_PROGRESS = "IN_PROGRESS",
  SUBMITTED = "SUBMITTED",
  VIDEO_REQUESTED = "VIDEO_REQUESTED",
  INTERVIEWING = "INTERVIEWING",
  PAYMENT_REQUESTED = "PAYMENT_REQUESTED",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
}

interface ApplicationCoreData {
  program_title: string;
  cohort_title: string;
}

export type ApplicationData = ApplicationCoreData & Record<string, unknown>;

export interface Application {
  id: string;
  email: string;
  data: ApplicationData;
  programSlug: string;
  cohortSlug: string;
  status: ApplicationStatusEnum;
  userId: string;
  createdAt: string;
  updatedAt: string;
}
